import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { DrupalTemplate, DrupalNode, DrupalProcessedText, DrupalMediaImage, DrupalLink, DrupalBanner } from '../drupal'
import { Microdata, getMetatagContent, getImageUrl } from '../Microdata'
import { media, widths } from '../style/variables'
import Layout from '../layouts'
import Container from '../components/Container'
import Stack from '../components/Stack'
import Banner from '../components/Banner'
import Media from '../components/fields/Media'
import { t } from '../i18n'
import ParticipateCard from '../components/ParticipateCard'

export interface RecipeNode extends DrupalNode {
	body: DrupalProcessedText
	field_recipe_portions?: number
	field_recipe_author?: DrupalLink
	relationships: {
		field_media: DrupalMediaImage
		field_banner?: DrupalBanner
	}
}

const StyledContainer = styled(Container)`
	/** Make content more readable by limiting max-width. */
	article {
		max-width: ${widths.md}px;
		margin: 0 auto;
	}

	button {
		color: white;
	}

	.author,
	.portions {
		display: block;
	}
`
const Wrapper = styled.div`
	margin: 40px 0;
	${media.down('xs')} {
		margin: 30px 0;
	}
`
const ImageWrapper = styled.div`
	margin-bottom: 40px;
	${media.down('xs')} {
		margin-bottom: 20px;
	}
`
const StyledH1 = styled.h1`
	margin-bottom: 12px;
	${media.down('xs')} {
		margin-bottom: 10px;
	}
`

function makeRecipeMicrodata(node: RecipeNode): Microdata {
	return [
		{
			'@context': 'http://schema.org',
			'@type': 'Recipe',
			name: getMetatagContent(node.metatag_normalized, 'name', 'title'),
			image: getImageUrl(node.relationships.field_media),
			description: getMetatagContent(node.metatag_normalized, 'name', 'description')
		}
	]
}

const PageTemplate: DrupalTemplate<RecipeNode> = ({ data: { node } }) => (
	<Layout node={node} microdata={makeRecipeMicrodata(node)}>
		<main>
			<Stack>
				<Banner image={node.relationships.field_banner} />
				<StyledContainer>
					<article>
						<ImageWrapper>
							<Media source={node.relationships.field_media} />
							{node.field_recipe_author && (
								<span className="author">
									{t('Recipe')}:{' '}
									{node.field_recipe_author.uri === 'route:<nolink>' ? (
										<>{node.field_recipe_author.title}</>
									) : (
										<a href={node.field_recipe_author.uri}>{node.field_recipe_author.title}</a>
									)}
								</span>
							)}
							{node.field_recipe_portions && (
								<span className="portions">
									{t('Portions')}: {node.field_recipe_portions}
								</span>
							)}
						</ImageWrapper>
						<StyledH1>{node.title}</StyledH1>
						<div dangerouslySetInnerHTML={{ __html: node.body.processed }} />
						{process.env.GATSBY_LANGUAGE === 'fi' && (
							<Wrapper>
								<ParticipateCard href="https://vegaanihaaste.fi/" />
							</Wrapper>
						)}
					</article>
				</StyledContainer>
			</Stack>
		</main>
	</Layout>
)

export default PageTemplate

export const pageQuery = graphql`
	query RecipeTemplateQuery($drupalId: String!) {
		node: nodeRecipe(drupal_id: { eq: $drupalId }) {
			langcode
			title
			metatag_normalized {
				tag
				attributes {
					name
					content
					property
					href
					rel
				}
			}
			body {
				processed
			}
			field_recipe_portions
			field_recipe_author {
				uri
				title
			}
			relationships {
				field_media {
					__typename
					...MediaImageDefault
				}
				field_banner {
					...DrupalBanner
				}
			}
		}
	}
`
